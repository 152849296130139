<template>
  <div class="" :style="'height:' + window.height + 'px'">
    <v-container fill-height fluid>
      <v-row
          align="center" justify="center" v-if="typeof result['infoFormMessage'] != 'undefined'">
        <v-col style="max-width: max-content">
          <messagesContainer :result="result"></messagesContainer>
        </v-col>
      </v-row>

      <v-row align="center" justify="center" style="">
        <v-col class="mr-9" style="max-width: 600px">
          <div
              class="" :style="{
              bottom: $vuetify.breakpoint.xl ? '108px' : '10px',
              'font-size': $vuetify.breakpoint.xs ? '1.5em' : '1.8em',
            }" style="position: relative">
            Login to Acctopus Degust
          </div>
          <v-form v-model="isValid" ref="form" @submit.prevent>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="user.username" label="Username (Email)" :rules="
                    getRules(
                      result.fields.c_email.fieldAttrInput,
                      this.result,
                      'c_email',
                      this
                    )
                  " name="c_email" id="loginc_email" placeholder=""></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="user.password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="
                    getRules(
                      result.fields.c_password.fieldAttrInput,
                      this.result,
                      'c_password',
                      this
                    )
                  "
                    :type="show1 ? 'text' : 'password'"
                    name="c_password"
                    label="Password"
                    @click:append="show1 = !show1"
                    id="loginc_password"
                    @keyup.enter="submitForm('requestlogin')"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="start" justify="center" class="mt-2">
                <primaryButton
                    label="Login"
                    @click="submitForm('requestlogin')"
                    type="button"
                    color="save"
                    id="login_requestlogin"
                    data-type="loginButton"
                    :disabled="!isValid || loading"
                    :loading="loading && isValid"
                    icon="mdi-login"
                    shrink=""/>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center" justify="center">
                <a href="?f=global&f2=register" class="ml-2">{{
                    language.componentLabel.labelRU
                  }}
                </a>
                <span class="seperator"> | </span>

                <router-link
                    id="resetPasswordLink" to="?f=global&f2=resetPasswordRequest" class="">
                  {{ language.componentLabel.labelFP }}
                </router-link>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="12" sm="5" style="max-width: 675px" class="mb-12">
          <div>
            <div :style="'height:' + spaceHeight"></div>
            <p style="font-weight: bold">
              Welcome back to Acctopus Degust®, the cloud-based telco test environment where enterprises, teams, and
              individuals test mobile and fixed networks' quality, protocols, usage, and compliance </p>
            <p
                style="font-weight: bold" :style="{
                display: $vuetify.breakpoint.xsOnly ? 'inline-grid' : '',
              }">
              <span style="padding-right: 30px">...to whatever extend</span>
              <span style="padding-right: 30px">...at any scale</span>
              <span
                  class="text-no-wrap">...in any size of teams</span>
            </p>
            <br/>
            <p>
              After logging in, you can try out our brand new 5G SBI test features and automated packet trace
              evaluation. </p>
            <p>
              Or simply turn Raspberry Pi computers into automated monitoring probes using our free-of-charge OS image.
              You will be able to perform extensive UDP and TCP tests on throughput, latency, jitter, RTT, or drops
              using well-known tools like iPerf or tools recommended by the Broadband Forum, the IETF, or other
              standardization bodies. Degust also supports publicly available speed test services as well as most of
              your existing scripts. </p>
            <p>
              By running the Degust Explorer image on hundreds or even thousands of devices, you can examine and report
              on the quality of your network in a very short time. </p>
            <p>
              If you are new to Degust®, please
              <router-link to="?f=global&f2=register">register</router-link>
              for free, get your OS image and monitor the world.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import primaryButton from '@/commonComponents/primaryButton.vue';
import {getRules} from '@/js/validators.js';
import messagesContainer from '@/commonComponents/messagesContainer.vue';

export default {
  components: {
    primaryButton,
    messagesContainer,
  },
  props: {
    result: {
      type: Object,
      /* default: function() {
          return { fields: {} };
        }*/
    },
  },

  data() {
    return {
      isValid: false,
      valueLine: {},
      user: {
        username:
            typeof this.$route.query.email != 'undefined'
                ? this.$route.query.email.replace(/ /g, '+')
                : '',
      },
      loading: false,
      message: '',
      show1: false,
      rules: [(value) => !!value || 'Required.'],
      window: {
        width: 0,
        height: 0,
      },
      errors: null,
    };
  },
  computed: {
    spaceHeight() {
      let height = '';
      // let winHeight = window.innerHeight;
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          height = '10px';
          break;
        case 'sm':
          height = '40px';
          break;
        case 'md':
          height = '60px';
          break;
        case 'lg':
          height = '80px';
          break;
        case 'xl':
          height = '70px';
          /*if(winHeight>2000){ //UHD
            height= '75px';
          }else if(winHeight>1000){//FHD
            height= '100px';
          }*/
          break;
      }
      return height;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'dialogButtonsLoading') {
        this.loading = state.dialogButtonLoading;
      }
    });
  },
  destroyed() {
    this.unsubscribe();
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    goRegister() {
      let orgURL = '?f=global&f2=register';
      this.$router.push(orgURL).catch(() => {
        this.$router.go();
      });
    },
    getRules: getRules,
    submitForm(functionName) {
      if (typeof this.$refs.form != 'undefined' && this.$refs.form.validate()) {
        this.loading = true;
        this.axios.post(`${process.env.VUE_APP_AUTH_SERVICE}/auth-service/v1/login`,
            {
              user: this.user.username,
              password: this.user.password,
            }).then((jwt) => {
          this.storeJwt(jwt.data);
          this.ajaxSubmit(
              functionName,
              this.$refs.form.$el,
              this.result.tableUrl,
              this.result,
              undefined,
              undefined,
              (response) => {
                this.frameworkPostRequest('?f=global&f2=login', 'saveAccessToken',
                    {accessToken: jwt.data.access_token},
                    () => {
                      this.updateProjectState(() => {
                        if (response.data.result.redirect) {
                          this.safeClick(response.data.result.redirect);
                        } else if (response.data.result.infoFormMessage) {
                          const text = response.data.result.infoFormMessage.startsWith('Incorrect') ?
                              response.data.result.infoFormMessage :
                              'The account with this email address has not been activated yet. Please check your mailbox for the activation request and follow the link in the email to activate your account.';
                          this.$store.commit('notification', {
                            text: text,
                            length: 10000,
                          });
                          this.loading = false;
                        }
                      });
                    });
              },
          );
        }).catch((error) => {
          if (error.response.status === 403) {
            this.$store.commit('notification', 'Invalid credentials.');
          } else {
            this.$store.commit('notification', 'Unknown network error. Please try again.');
          }
          this.loading = false;
        });
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight - 250;
    },
  },
};
</script>
<style scoped>
.buttton {
  width: 180px;
}

.seperator {
  color: rgba(153, 150, 150, 0.6);
}
</style>
